<template>
  <b-modal ref="error-popup" hide-footer>
    <div class="row">
      <div class="col-12 text-center">
        <i class="fa fa-minus-circle" style="font-size: 42px; color: #fe0000;"></i>
      </div>
    </div>
    <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
      <div class="col-12 text-center">
        <h4 class="event-name" style="text-align: center;">{{ header }}</h4>
        <br />
        <p>
          {{ text }}
        </p>

        <p v-if="local_error_extras">{{ local_error_extras }}</p>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'error-popup',
  data() {
    return {
      local_error: null,
      local_error_extras: null,

      defined_errors: {
        ERR_INVITE_TEMPLATE_NOT_SET: true,
        ERR_TODO_MUST_SET_COMPANY_IDS: true,
        ERR_TODO_SET_DATE: true,
        ERR_REFUND_AMOUNT_ABOVE_0: true,
        ERR_CANT_REMOVE_ALL_MEMBERSHIPS: true,
        ERR_CANT_REMOVE_MEMBERSHIP_WITH_INVOICE: true,
        ERR_PENDING_PAYMENTS_MEMBER: true,
        ERR_NO_MEMBERS_IN_SENDOUT_QUERY: true,
        ERR_MEMBER_ALREADY_EXISTS: true,
        ERR_EMAIL_ALREADY_REGISTERED: true,
        ERR_MEMBER_MISSING_SKV_PERIOD: true,
        ERR_MEMBER_SET_REG: true,
        ERR_FORBIDDEN: true,
        // todo add
        ERR_EVENTSIGNUP_ANY_MEMBER_PAID: true,
        ERR_EVENTSIGNUP_COMPANY_MEMBER_PAID: true,
        ERR_EVENTSIGNUP_MAX_TICKETS: true,
        ERR_EVENTSIGNUP_ANY_MEMBER: true,
        ERR_EVENTSIGNUP_INACTIVE_MEMBERSHIP: true,
        ERR_EVENTSIGNUP_MEMBERS_ONLY: true,
        ERR_EVENTSIGNUP_UNDEFINED_ERROR: true,
        ERR_MISSING_REQUIRED_PAGE_CONFIRMPAGE: true,
        ERR_409_GROUP: true,
        ERR_RENEWS_HAVE_ERRORS: true
        
      }
      
    };
  },
  props: {
    error: String,
    error_extras: String
  },
  async mounted() {

    if (this.error === "" || this.error === null) {
      return;
    }

    if (!(this.error in this.defined_errors)) {
      console.error('error message not defined: ' + this.error);
      return;
    }

    this.local_error = this.error;
    this.local_error_extras = this.error_extras;

    this.show()
  },
  watch: {
    error() {

      if (this.error === "" || this.error === null) {
        return;
      }

      if (!(this.error in this.defined_errors)) {
        console.error('error message not defined: ' + this.error);
        return;
      }

      this.local_error = this.error;
      this.local_error_extras = this.error_extras;

      this.show();
    }
  },
  methods: {
    show() {
      this.$refs['error-popup'].show();
    },
    onOk() {
      this.$refs['error-popup'].hide();
    },
  },
  computed: {
    header() {
      if (this.local_error === "" || this.local_error === null) {
        return '';
      }

      return this.$t(`ERRORS.HEADERS.${this.local_error}`)
    },
    text() {
      if (this.local_error === "" || this.local_error === null) {
        return '';
      }

      return this.$t(`ERRORS.TEXTS.${this.local_error}`)
    }
  }
};
</script>
